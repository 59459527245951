<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
      <internal-menu :menuItems="menuItems" />
    </v-row>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false">Ok</v-btn>
      </template>
    </v-snackbar>
    <v-row justify="center">
      <v-card>
        <v-card-text>
          <v-container>
            <v-form class="mt-4" ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="5" class="mt-n8">
                  <v-text-field
                    outlined
                    label="Nome Completo"
                    v-model="owner.person.corporateName"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" class="mt-n8">
                  <v-text-field
                    outlined
                    label="Nick Name"
                    v-model="owner.person.tradeName"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="mt-n8">
                  <v-autocomplete
                    outlined
                    :items="cells"
                    item-value="id"
                    item-text="name"
                    v-model="owner.groupId"
                    label="PGM"
                    :loading="loading"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="3" class="mt-n8">
                  <v-select
                    outlined
                    v-model="owner.person.gender"
                    :items="genders"
                    item-text="text"
                    item-value="value"
                    label="Gênero"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4" class="mt-n8">
                  <v-text-field
                    outlined
                    v-mask="maskPhoneMobile"
                    label="WhatsApp"
                    v-model="owner.contact.whatsApp"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="mt-n8">
                  <v-switch
                    label="Cadastro Completo"
                    @click="fullRegister = !fullRegister"
                  ></v-switch>
                </v-col>
              </v-row>

              <v-row v-if="fullRegister">
                <v-col cols="12" sm="5" class="mt-n8">
                  <v-text-field
                    outlined
                    v-mask="maskPhoneMobile"
                    label="Celular"
                    v-model="owner.contact.phoneMobile"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="7" class="mt-n8">
                  <v-text-field
                    outlined
                    label="Email"
                    v-model="owner.contact.email"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="mt-n8">
                  <v-text-field
                    outlined
                    type="date"
                    label="Data Nascimento"
                    v-model="owner.person.birthDate"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="mt-n8">
                  <v-text-field
                    outlined
                    type="date"
                    label="Data Conversão"
                    v-model="owner.conversionDate"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="mt-n8">
                  <v-text-field
                    outlined
                    type="date"
                    label="Data Batismo"
                    v-model="owner.integrationDate"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="4" class="mt-n8">
                  <v-text-field
                    outlined
                    v-mask="'#####-###'"
                    label="CEP"
                    v-model="owner.address.zipCode"
                    required
                    @change="searchAddress()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="mt-n8">
                  <v-text-field
                    outlined
                    label="Logradouro"
                    v-model="owner.address.street"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" class="mt-n8">
                  <v-text-field
                    outlined
                    label="Número"
                    v-model="owner.address.number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" class="mt-n8">
                  <v-text-field
                    outlined
                    label="Complemento"
                    v-model="owner.address.complement"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" class="mt-n8">
                  <v-text-field
                    outlined
                    label="Bairro"
                    v-model="owner.address.zone"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="mt-n8">
                  <v-text-field
                    outlined
                    label="Cidade"
                    v-model="owner.address.city"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" class="mt-n8">
                  <v-text-field
                    outlined
                    label="UF"
                    v-model="owner.address.state"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="8" class="mt-n8">
                  <v-textarea
                    outlined
                    label="Observações"
                    :rows="5"
                    v-model="owner.notes"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="4" class="mt-n8">
                  <v-btn text class="black--text" @click.native="openFileDialog">
                    {{ fileName ? fileName : "FOTO" }}
                    <v-icon right dark>mdi-camera</v-icon>
                  </v-btn>
                  <input
                    ref="fileRef"
                    type="file"
                    id="file-upload"
                    style="display: none"
                    @change="handleFileUpload"
                  />
                  <v-img
                    height="80"
                    width="80"
                    :src="urlPreview"
                    @click.native="openFileDialog"
                  ></v-img>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  :disabled="!valid"
                  :loading="loadingAction"
                  width="200px"
                  color="#2ca01c"
                  dark
                  @click="validate()"
                >
                  Salvar
                </v-btn>
              </v-card-actions>

              <!--
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :loading="loading"
                        :headers="headers"
                        :items="presences"
                        disable-filtering
                        disable-pagination
                        disable-sort
                        hide-default-footer
                      >
                        <template v-slot:[`item.date`]="{ item }">
                          <span>{{ dateFormat(item.date) }}</span>
                        </template>
                        <template v-slot:[`item.presenceVisitor`]="{ item }">
                          <v-switch
                            small
                            v-model="item.presenceVisitor"
                            disabled
                          ></v-switch>
                        </template>

                        <template v-slot:[`item.withBible`]="{ item }">
                          <v-switch small v-model="item.withBible" disabled></v-switch>
                        </template>

                        <template v-slot:[`item.withVisitor`]="{ item }">
                          <v-switch small v-model="item.withVisitor" disabled></v-switch>
                        </template>

                        <template v-slot:[`item.completedChallenge`]="{ item }">
                          <v-switch
                            small
                            v-model="item.completedChallenge"
                            disabled
                          ></v-switch>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
-->
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { VMoney } from "v-money";
import SearchAddressService from "@/services/address.service";
import InternalMenu from "@/components/core/InternalMenu.vue";
import PageHeader from "@/components/core/PageHeader.vue";
import moment from "moment";

export default Vue.extend({
  name: "MemberForm",
  directives: { money: VMoney },
  components: { InternalMenu, PageHeader },
  data: () => ({
    title: "Membros",
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Aniversariantes",
            to: "/customers/birthdays",
            permission: "gerentor.person.customers.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Grupos/Células",
            to: "/promise/cells",
            permission: "promise.cells.get_many",
          },
        ],
      },
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Membros",
        disabled: false,
        href: "/members",
      },
      {
        text: "Cadastro Membro",
        disabled: true,
        href: "/members",
      },
    ],
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },
    tab: null,
    fullRegister: false,
    zipCode: "",
    cells: [],
    members: [],
    search: "",
    loading: false,
    loadingAction: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    maskPhone: "(##)####-####",
    maskCpf: "###.###.###-##",
    maskCnpj: "##.###.###/####-##",
    maskPhoneMobile: "(##)#####-####",
    valid: true,
    owner: {
      active: true,
      groupId: null,
      notes: "",
      leaderId: null,
      originId: null,
      conversionDate: null,
      integrationDate: null,
      person: {
        corporateName: "",
        tradeName: "",
        idDocument: "",
        personType: "FISICA",
        stateDocument: "",
        gender: "",
        maritalStatus: "",
        fatherName: "",
        motherName: "",
        conjugeDocument: "",
        birthDate: null,
        profileUrl: null,
      },
      address: {
        street: "",
        number: "",
        complement: "",
        zone: "",
        city: "",
        state: "",
        zipCode: "",
      },
      contact: {
        email: "",
        phoneLandline: "",
        phoneMobile: "",
        whatsApp: "",
      },
    },
    id: "",
    genders: [
      {
        value: "F",
        text: "Feminino",
      },
      {
        value: "M",
        text: "Masculino",
      },
    ],
    file: "",
    fileName: "",
    urlPreview: "https://cdn.pixabay.com/photo/2017/01/10/03/54/avatar-1968236__340.png",
    headers: [
      {
        text: "Data",
        align: "start",
        value: "date",
        filtering: true,
      },
      {
        text: "Nome",
        align: "start",
        value: "member.person.corporateName",
        filtering: true,
      },
      {
        text: "Presente?",
        align: "start",
        value: "presenceVisitor",
        filtering: true,
      },
      {
        text: "Com Bíblia?",
        align: "start",
        value: "withBible",
        filtering: true,
      },
      {
        text: "Com Visitante?",
        align: "start",
        value: "withVisitor",
        filtering: true,
      },
      {
        text: "Cumpriu Desafio?",
        align: "start",
        value: "completedChallenge",
        filtering: true,
      },
      {
        text: "Pontuação",
        align: "start",
        value: "points",
        filtering: true,
      },
    ],
    presences: [],
  }),
  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) this.registerOrUpdate();
    },

    handleFileUpload() {
      this.file = this.$refs.fileRef.files[0];
      this.fileName = this.$refs.fileRef.files[0].name;
      this.urlPreview = URL.createObjectURL(this.file);
    },
    openFileDialog() {
      document.getElementById("file-upload").click();
    },

    async searchAddress() {
      const zipCode = this.owner.address.zipCode.replace(/([^\d])+/gim, "");
      const res = await SearchAddressService(zipCode);
      if (res.success) {
        res.address.zipCode = this.owner.address.zipCode;
        this.owner.address = res.address;
      } else {
        this.snackbar.text = "Houve erro ao buscar o CEP";
        this.snackbar.color = "orange";
        this.snackbar.opened = true;
      }
    },

    dateFormat(referenceDate) {
      return moment(referenceDate).format("DD/MM/YYYY");
    },

    getItem() {
      const id = this.$route.params.id;
      if (id) {
        this.loading = true;
        this.id = id;
        http.get(`/gerentor/customers/${id}`).then(
          (res) => {
            const { data } = res;
            this.owner = data;
            if (this.owner?.person?.profileUrl) {
              this.urlPreview = this.owner.person.profileUrl;
            }
            http.get(`/promise/presences?filter=memberId||eq||${id}`).then((res) => {
              this.presences = res.data;
            });
            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
      }
    },
    async registerOrUpdate() {
      this.loadingAction = true;
      if (this.file) {
        const name = this.owner.person.corporateName.replace(/ /g, "").toLowerCase();
        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("name", name);
        formData.append("path", "promise/profiles");
        formData.append("type", "avatar");
        formData.append("public", 1);
        await http.post("delivery/marketing/files", formData).then(
          (d) => {
            this.owner.person.profileUrl = d.data.imageUrl;
          },
          () => {
            this.snackbar.text =
              "Houve um erro ao fazer upload da Foto de Perfil, tente novamente";
            this.snackbar.opened = true;
            this.snackbar.color = "red";
          }
        );
      }

      if (this.id) {
        http.put(`gerentor/customers/${this.id}`, this.owner).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Atualizado com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.$router.push("/members");
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Atualizar. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      } else {
        http.post("gerentor/customers", this.owner).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Inserido com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.$router.push("/members");
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Registro. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      }
    },
    resetForm() {
      this.$refs.form.reset();
    },

    getCells() {
      this.loading = true;
      http.get("promise/cells?filter=type||eq||group").then(
        (data) => {
          this.cells = data.data;
          this.loading = false;
        },
        () => (this.loading = false)
      );
    },

    getMembers() {
      this.loading = true;
      http.get("gerentor/customers").then(
        (data) => {
          this.members = data.data;
          if (this.id) this.members = this.members.filter((a) => a.id !== this.id);
          this.loading = false;
        },
        () => (this.loading = false)
      );
    },
  },
  mounted() {
    this.getMembers();
    this.getCells();
    this.getItem();
  },
});
</script>

<style>
.label-outlined {
  font-size: 12px;
  color: grey;
}
</style>
